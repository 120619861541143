import React from "react";
import Drawer from "~/components/Drawer/Drawer";
import SocialNetworks from "../SocialNetworks";

// import { Link } from "gatsby";

const MiniDrawer = ({ entry, buttonText, buttonStyle, ...props }) => {
	return (
		<Drawer
			buttonText={buttonText ? buttonText : entry.title}
			buttonStyle={buttonStyle ? buttonStyle : null}
			title={entry.title}
			topExtra={
				entry.socializr ? (
					<div>
						<SocialNetworks items={entry.socializr} mini={true} />
					</div>
				) : null
			}
			address={
				entry.addressSelector &&
				entry.addressSelector.length &&
				entry.addressSelector[0].title
			}
			tinyImageUrl={
				entry.featuredImage &&
				entry.featuredImage.length &&
				entry.featuredImage[0].url + "?sat=-100&w=73&h=73&fit=crop&crop=entropy"
			}
			imageUrl={
				entry.featuredImage &&
				entry.featuredImage.length &&
				entry.featuredImage[0].url +
					"?sat=-100&w=400&h=225&fit=crop&crop=entropy"
			}
			imageObject={
				!entry.featuredImage || !entry.featuredImage.length
					? null
					: entry.featuredImage[0]
			}
			category={
				entry.locationCategory && entry.locationCategory.length ? (
					<h4 className="text-xs uppercase mb-1 font-din">
						{entry.locationCategory[0].title}
					</h4>
				) : entry.companyIndustry && entry.companyIndustry.length ? (
					<h4 className="text-xs uppercase mb-1 font-din">
						{entry.companyIndustry[0].title}
					</h4>
				) : null
			}
			bodyPart={
				entry.body && (
					<div
						className="reading-smaller p-6"
						dangerouslySetInnerHTML={{
							__html: entry.body.content
						}}
					/>
				)
			}
			mainLink={`/${entry.uri}`}
		/>
	);
};

export default MiniDrawer;
