import React from "react";
import { Link } from "gatsby";
// import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
// import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import { Paper } from "@material-ui/core";
// import LazyLoad from "react-lazy-load";
// import { MdKeyboardArrowRight } from "react-icons/md";
// import Icon from "@material-ui/core/Icon";
// import IconButton from "@material-ui/core/IconButton";
// import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import MicroImage from "../ImageAtom";

const styles = {
	title: {
		fontSize: 14
	},
	list: {
		width: 250
	},
	fullList: {
		width: "auto"
	},
	media: {
		// ⚠️ object-fit is not supported by IE 11.
		objectFit: "cover"
	}
};

const badgeStyles =
	"inline-block bg-grey-lightest rounded-full px-3 py-1 text-grey-darker text-xs uppercase font-semibold mr-1 mb-2";

class TemporaryDrawer extends React.Component {
	state = {
		top: false,
		left: false,
		bottom: false,
		right: false
	};

	toggleDrawer = (side, open) => () => {
		this.setState({
			[side]: open
		});
	};

	render() {
		const {
			title,
			address,
			topExtra,
			imageObject,
			category,
			buttonText,
			buttonStyle,
			mainLink,
			bodyPart
		} = this.props;

		const sideList = null;

		// const fullList = (
		// 	<div className={classes.fullList}>
		// 		<List>
		// 			<ListItem button>
		// 				<Avatar alt="Remy Sharp" src={tinyImageUrl} />
		// 				<ListItemText primary={title} secondary={address} />
		// 			</ListItem>
		// 		</List>
		// 	</div>
		// );

		return (
			// 	<div className="inline-block">
			// 		{buttonStyle && buttonStyle == "badge" ? (
			// 			<button
			// 				className={badgeStyles + " md:hidden"}
			// 				onClick={this.toggleDrawer("bottom", true)}
			// 			>
			// 				{buttonText ? buttonText : "More info"}
			// 				<span>&darr;</span>
			// 			</button>
			// 		) : (
			// 			<Button
			// 				size="small"
			// 				color="primary"
			// 				classes={{
			// 					root: "rounded-none",
			// 					label: "font-dincond uppercase font-bold text-lg"
			// 				}}
			// 				onClick={this.toggleDrawer("bottom", true)}
			// 			>
			// 				{buttonText ? buttonText : "More info"}
			// 			</Button>
			// 		)}

			// 		<Drawer
			// 			anchor="bottom"
			// 			open={this.state.bottom}
			// 			onClose={this.toggleDrawer("bottom", false)}
			// 		>
			// 			{/* <h1>{title}</h1>
			// <h2>{address}</h2> */}
			// 			<div
			// 				tabIndex={0}
			// 				role="button"
			// 				onClick={this.toggleDrawer("bottom", false)}
			// 				onKeyDown={this.toggleDrawer("bottom", false)}
			// 			>
			// 				{fullList}
			// 			</div>
			// 		</Drawer>

			<div className="inline-block">
				{buttonStyle && buttonStyle === "badge" ? (
					<button
						className={badgeStyles}
						onClick={this.toggleDrawer("right", true)}
					>
						{buttonText ? buttonText : "Button"}
					</button>
				) : (
					<Button
						size="small"
						color="primary"
						classes={{
							root: "rounded-none",
							label: "font-dincond uppercase font-bold text-lg"
						}}
						onClick={this.toggleDrawer("right", true)}
					>
						{buttonText ? buttonText : "More info"}
					</Button>
				)}

				<Drawer
					anchor="right"
					open={this.state.right}
					onClose={this.toggleDrawer("right", false)}
					classes={{
						root: "classes-state-root",
						paperAnchorRight: "w-90vw md:w-75vw lg:w-40vw cardboard"
					}}
				>
					<div
						tabIndex={0}
						role="button"
						onClick={this.toggleDrawer("right", false)}
						onKeyDown={this.toggleDrawer("right", false)}
					>
						{imageObject ? (
							<div className="bg-black border-black border-b-8">
								<MicroImage
									imageObject={imageObject}
									title={title}
									avatarStyle={false}
									aspectRatio={"16x9"}
									placeholder={"https://placehold.it/400x225"}
									imgixParams={"?w=400&h=225&fit=crop&crop=entropy"}
									imageClasses={"w-full"}
									lazyClasses={"aspect-ratio-16/9"}
								/>
							</div>
						) : null}
						<div className="p-6">
							{category}
							<h1 className="text-5xl md:text-6xl lg:text-8xl uppercase font-dincond font-black md:font-bold  leading-mini mt-2 mb-4">
								{title}
							</h1>
							<h2 className="font-dincond uppercase font-black">{address}</h2>
							<div>
								<Link to={mainLink}>
									<h2 className="text-2xl leading-none font-dincond uppercase font-black text-black group-hover:text-blue">
										{title}
									</h2>
								</Link>
							</div>
							{topExtra}
						</div>

						<Paper
							square={true}
							elevation={4}
							classes={{
								root: "m-6"
							}}
						>
							{bodyPart}
						</Paper>

						{sideList}
					</div>
				</Drawer>
			</div>
			// </div>
		);
	}
}

// TemporaryDrawer.propTypes = {
// 	classes: PropTypes.object.isRequired
// };

export default withStyles(styles)(TemporaryDrawer);

// <Button onClick={this.toggleDrawer("bottom", true)}>Open Bottom</Button>
// 				<Drawer
// 					anchor="bottom"
// 					open={this.state.bottom}
// 					onClose={this.toggleDrawer("bottom", false)}
// 				>
// 					<h1>{title}</h1>
// 					<h2>{address}</h2>
// 					<div
// 						tabIndex={0}
// 						role="button"
// 						onClick={this.toggleDrawer("bottom", false)}
// 						onKeyDown={this.toggleDrawer("bottom", false)}
// 					>
// 						{fullList}
// 					</div>
// 				</Drawer>

// <ListItemIcon>
// 							<MailIcon />
// 						</ListItemIcon>

// <CardMedia
// 									component="img"
// 									alt="Contemplative Reptile"
// 									className={classes.media}
// 									height="140"
// 									image={imageUrl}
// 									title="Contemplative Reptile"
// 								/>

// <p className="mb-8">
// <IconButton aria-label="Close">
// 	<ArrowForward />
// </IconButton>
// </p>
