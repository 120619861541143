/* global tw */
import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import TextTruncate from "react-text-truncate";
import MicroImage from "../ImageAtom";

const CardBlockWrapper = styled.div`
	${tw`flex pr-4 group text-black trans`};
	flex: 0 0 auto;
`;

const CardBg = styled.div`
	${tw`flex relative cursor-pointer flex-col h-full bg-white trans overflow-hidden w-240 md:w-400 md:flex-row-reverse min-h-300 md:min-h-0`};
`;

const CardContent = styled.div`
	${tw`flex flex-col flex-1 flex-grow flex-shink`};
	padding: 20px;
	@media only screen and (min-width: 1025px) {
		-webkit-box-flex: 1;
		-ms-flex: 1 1;
		flex: 1 1;
		padding: 20px 30px 0;
		-webkit-box-direction: reverse;
	}
`;

const CardImage = styled.div`
	${tw`w-full h-135 md:w-150 md:h-150`};
	@media only screen and (min-width: 1025px) {
		-webkit-box-direction: reverse;
	}
`;

const SecondLine = styled.p`
	${tw`text-xs leading-tight font-dincond uppercase font-black`};
`;

const CatCrumb = styled.h3`
	${tw`text-xs uppercase mb-1 text-red hover:text-red-darker`};
`;

const Card = ({
	title,
	mainLink,
	imageObject,
	imageUrl,
	tinyImageUrl,
	placeholderUrl,
	lineTwo,
	catName,
	extraStuff,
	noBorder,
	...props
}) => {
	return (
		<CardBlockWrapper className={props.avatarStyle ? "mb-0" : "mb-6"}>
			<CardBg
				className={
					props.noBorder
						? "is-bw-img hover-color transition-duration-300 transition-timing-ease-in group-hover:shadow-lg"
						: "is-bw-img hover-color transition-duration-300 transition-timing-ease-in group-hover:shadow-lg border-b-3 border-red"
				}
			>
				<Link to={mainLink}>
					<CardImage
						className={props.avatarStyle ? "hidden md:block w-full" : ""}
					>
						<MicroImage
							imageObject={imageObject}
							title={title}
							avatarStyle={props.avatarStyle}
							aspectRatio={"16x9"}
							placeholder={"https://placehold.it/240x135"}
							imgixParams={"?w=240&h=135&fit=crop"}
							imageClasses={"object-fit-cover h-full"}
							lazyClasses={"aspect-ratio-16/9 md:aspect-ratio-square"}
						/>
					</CardImage>
				</Link>

				<CardContent>
					<CatCrumb>{catName}</CatCrumb>

					<h2 className="text-2xl leading-none font-dincond uppercase font-black">
						<Link
							to={mainLink}
							className="text-black hover:text-red transition-duration-300 transition-timing-ease-in"
						>
							<TextTruncate line={2} truncateText="…" text={title} />
						</Link>
					</h2>

					<SecondLine>{lineTwo}</SecondLine>
					{extraStuff}
					{props.children}
				</CardContent>
			</CardBg>
		</CardBlockWrapper>
	);
};
export default Card;

// import { LazyLoadImage } from "react-lazy-load-image-component";
// import LazyLoad from "react-lazy-load";

// const CardBlock = styled.div`
// 	${tw`w-full md:w-1/3 flex px-4 mb-6 group text-black trans`};
// `;

// {props.avatarStyle ? (
//     <Link to={mainLink}>
//         <div className={"flex md:hidden w-24 h-24 relative mr-4"}>
//             <MicroImage
//                 imageObject={imageObject}
//                 title={title}
//                 avatarStyle={true}
//                 placeholder={"https://placehold.it/73x73"}
//                 imgixParams={"?w=73&h=73&fit=crop&crop=entropy"}
//                 imageClasses={"w-16 h-16 rounded-full mr-4 ml-0"}
//                 lazyClasses={"aspect-ratio-square"}
//             />
//         </div>
//     </Link>
// ) : null}
