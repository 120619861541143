/* global tw */
import React from "react";
import ReactDOM from "react-dom";
import { Link } from "gatsby";
import Slider from "react-slick";
import styled from "styled-components";
import MicroImage from "../ImageAtom";
import SmartCard from "../Card/SmartCard";
import MiniDrawer from "../Drawer/MiniDrawer";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const SliderCardWrapper = styled.div`
	${tw`pr-4`};
	flex: 0 0 auto;
`;

const SliderCard = styled.div`
	${tw`flex relative cursor-pointer flex-col md:flex-row h-full bg-white`};
	width: 240px;
	@media only screen and (min-width: 1025px) {
		flex-direction: row-reverse;
		width: 400px;
	}
`;

const SliderCardContent = styled.div`
	${tw``};
	paddin: 20px;

	@media only screen and (min-width: 1025px) {
		-webkit-box-flex: 1;
		-ms-flex: 1 1;
		flex: 1 1;
		padding: 20px 30px 0;
		-webkit-box-direction: reverse;
	}
`;

const SliderCardImg = styled.div`
	width: 100%;
	height: 135px;

	@media only screen and (min-width: 1025px) {
		width: 150px;
		height: 150px;
		-webkit-box-direction: reverse;
	}
`;

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", color: "#333" }}
			onClick={onClick}
		>
			<MdKeyboardArrowRight />
		</div>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", color: "#333" }}
			onClick={onClick}
		>
			<MdKeyboardArrowLeft />
		</div>
	);
}

// $NOTE seemingly, variableWidth allows the width to actually be honored as opposed to overwritten by the 'slides to show' variable

class SimpleSlider extends React.Component {
	render() {
		const settings = {
			className: "center",
			arrows: true,
			infinite: true,
			centerPadding: "60px",
			slidesToShow: 3,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			swipe: true,
			swipeToSlide: true,
			variableWidth: true,
			afterChange: function(index) {
				console.log(
					`Slider Changed to: ${index + 1}, background: #222; color: #bada55`
				);
			},
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 3,

						infinite: true,
						dots: true
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,

						initialSlide: 2
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		const { speed, items, withDrawer } = this.props;
		return (
			<Slider {...settings}>
				{items.map((entry, i) => (
					<SmartCard
						key={i}
						avatarStyle={false}
						mainLink={`/${entry.uri}`}
						title={"Hi " + entry.title}
						catName={
							entry.preparseGlobalCategory ? entry.preparseGlobalCategory : null
						}
						imageObject={
							!entry.featuredImage || !entry.featuredImage.length
								? null
								: entry.featuredImage[0]
						}
						lineTwo={
							entry.addressSelector && entry.addressSelector.length
								? entry.addressSelector[0].title
								: null
						}
					>
						{withDrawer ? (
							<MiniDrawer entry={entry} buttonText="More Info" />
						) : null}
					</SmartCard>
				))}
			</Slider>
		);
	}
}

export default SimpleSlider;

// <img src="http://placekitten.com/g/600/400" />
